import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@redux/reducers/rootReducer';

const campaignsState = (state: RootState) => state.campaigns;

const getManageSinglePropertyCampaignState = createSelector(
  campaignsState,
  (state) => state.manageSinglePropertyCampaign
);

const getManageMultiPropertyCampaignState = createSelector(
  campaignsState,
  (state) => state.manageMultiPropertyCampaign
);

const getCampaignsListState = createSelector(campaignsState, (state) => state.list);

const getCampaignRecipientsState = createSelector(campaignsState, (state) => state.recipients);

const getCampaignResultsState = createSelector(campaignsState, (state) => state.results);

export const campaignsSelectors = {
  getManageSinglePropertyCampaignState,
  getManageMultiPropertyCampaignState,
  getCampaignsListState,
  getCampaignRecipientsState,
  getCampaignResultsState
};
