import { PayloadAction } from '@reduxjs/toolkit';
import { CancelToken } from 'axios';
import { call, put, select } from 'redux-saga/effects';

import { getCancelRequestSource, getHTTPClient } from '@core/http-client';
import { configActions } from '@redux/reducers/config/configReducer';
import { savedViewSocietyDisposalInsightsActions } from '@redux/reducers/saved_views/society-insights/savedViewSocietyDisposalInsightsReducer';
import { savedViewSocietyDisposalInsightsSelectors } from '@redux/selectors/saved_views/society-insights/savedViewSocietyDisposalInsightsSelector';
import {
  ManageSavedViewRequestPayload,
  PinUnpinSavedViewRequestPayload,
  SavedViewState,
  UpdateSavedViewRequestPayload,
} from '@redux/types/saved-views/common/savedViewsCommonTypes';
import { showNotification } from '@shared/components/notification';
import { getDefaultError } from '@shared/utils/common';


const $http = getHTTPClient();

const getSocietyDisposalInsights = () => {
  return $http.get(`/saved-views/society-disposals-insights`);
};

const getSocietyDisposalInsightsById = (id: Id) => {
  return $http.get(`/saved-views/society-disposals-insights/${id}`);
};

const updatePinnedSocietyDisposalInsightsSavedViews = (
  { userId, pinnedIds }: UpdateSavedViewRequestPayload,
  cancelToken: CancelToken
) => {
  return $http.put(
    `/users/${userId}/update/pinned_views_society_disposal_insights`,
    {
      pinned_views_society_disposal_insights: pinnedIds,
    },
    { cancelToken }
  );
};

const deleteSavedView = (id: Id) => {
  return $http.delete(`/saved-views/${id}`);
};

const editSavedView = (data: ManageSavedViewRequestPayload) => {
  return $http.put(`/saved-views/${data.id}`, data);
};

const createSavedView = (data: ManageSavedViewRequestPayload) => {
  return $http.post(`/saved-views/society-disposals-insights`, data);
};

export function* getSocietyDisposalInsightsSaga() {
  try {
    yield put(savedViewSocietyDisposalInsightsActions.setListLoading(true));

    const { data } = yield call(getSocietyDisposalInsights);
    yield put(savedViewSocietyDisposalInsightsActions.getListSuccess(data.data));
  } catch (e) {
    const errorMessage = getDefaultError(e?.message);

    showNotification(errorMessage, 'error');
    yield put(savedViewSocietyDisposalInsightsActions.getListFailure(errorMessage));
  }
}

export function* getSocietyDisposalInsightsByIdSaga(action: PayloadAction<Id>) {
  try {
    yield put(savedViewSocietyDisposalInsightsActions.setUnpinnedViewLoading(true));

    const { data } = yield call(() => getSocietyDisposalInsightsById(action.payload));
    yield put(savedViewSocietyDisposalInsightsActions.getUnpinnedViewSuccess(data));
  } catch (e) {
    yield put(savedViewSocietyDisposalInsightsActions.getUnpinnedViewFailure(getDefaultError(e?.message)));
  }
}

export function* updatePinnedSocietyDisposalInsightsSavedViewsSaga(
  action: PayloadAction<UpdateSavedViewRequestPayload>
) {
  const source = getCancelRequestSource();

  try {
    yield put(savedViewSocietyDisposalInsightsActions.setUpdateViewsRequestLoading(true));

    const { data } = yield call(() => updatePinnedSocietyDisposalInsightsSavedViews(action.payload, source.token));

    yield put(configActions.setUser(data));
    yield put(savedViewSocietyDisposalInsightsActions.updateViewsSuccess());
  } catch (e) {
    yield put(savedViewSocietyDisposalInsightsActions.updateViewsFailure(getDefaultError(e?.message)));
  } finally {
    const { updateLoading }: SavedViewState = yield select(savedViewSocietyDisposalInsightsSelectors.getState);

    if (updateLoading) {
      source.cancel();
    }
  }
}

export function* pinSocietyDisposalInsightsSavedViewsSaga(action: PayloadAction<PinUnpinSavedViewRequestPayload>) {
  const source = getCancelRequestSource();

  try {
    yield put(savedViewSocietyDisposalInsightsActions.setUpdateViewsRequestLoading(true));

    const { data } = yield call(() => updatePinnedSocietyDisposalInsightsSavedViews(action.payload, source.token));
    yield put(configActions.setUser(data));
    yield put(
      savedViewSocietyDisposalInsightsActions.pinViewSuccess({
        view: action.payload.view,
        isActiveView: action.payload.isActiveView,
      })
    );
  } finally {
    const { updateLoading }: SavedViewState = yield select(savedViewSocietyDisposalInsightsSelectors.getState);

    if (updateLoading) {
      source.cancel();
    }
  }
}

export function* unpinSocietyDisposalInsightsSavedViewsSaga(action: PayloadAction<PinUnpinSavedViewRequestPayload>) {
  const source = getCancelRequestSource();

  try {
    yield put(savedViewSocietyDisposalInsightsActions.setUpdateViewsRequestLoading(true));

    const { data } = yield call(() => updatePinnedSocietyDisposalInsightsSavedViews(action.payload, source.token));
    yield put(configActions.setUser(data));
    yield put(
      savedViewSocietyDisposalInsightsActions.unpinViewSuccess({
        view: action.payload.view,
        isActiveView: action.payload.isActiveView,
      })
    );
  } finally {
    const { updateLoading }: SavedViewState = yield select(savedViewSocietyDisposalInsightsSelectors.getState);

    if (updateLoading) {
      source.cancel();
    }
  }
}

export function* deleteSavedViewSocietyDisposalInsightsSaga(action: PayloadAction<Id>) {
  try {
    yield call(() => deleteSavedView(action.payload));

    yield put(configActions.deleteSavedViewSocietyDisposalInsightsSuccess(action.payload));
    yield put(savedViewSocietyDisposalInsightsActions.deleteViewSuccess(action.payload));
  } catch (e) {
    showNotification(getDefaultError(e?.message), 'error');
  }
}

export function* editSavedViewSocietyDisposalInsightsSaga(action: PayloadAction<ManageSavedViewRequestPayload>) {
  try {
    const { data } = yield call(() => editSavedView(action.payload));

    yield put(configActions.editSavedViewSocietyDisposalInsightsSuccess(data));
    yield put(savedViewSocietyDisposalInsightsActions.editViewSuccess(data));
  } catch (e) {
    showNotification(
      getDefaultError('Sorry, we could not update your view. Please contact us for assistance.'),
      'error'
    );
  }
}

export function* createSavedViewSocietyDisposalInsightsSaga(action: PayloadAction<ManageSavedViewRequestPayload>) {
  try {
    const { data } = yield call(() => createSavedView(action.payload));

    yield put(savedViewSocietyDisposalInsightsActions.createViewSuccess(data));
  } catch (e) {
    showNotification(getDefaultError(e?.message), 'error');
  }
}
