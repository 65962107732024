import { ITheme } from '@shared/models/theme';
import { IndexResponse, ListResponsePagination } from '@shared/types/services';

export interface MarketplaceMembersState {
  loading: boolean;
  error?: string;
  list: MarketplaceMember[];
  pagination?: ListResponsePagination;
}

export interface MarketplaceMember {
  id: Id;
  email: string;
  forename: string;
  mobile: string;
  name: string;
  position: string;
  surname: string;
  tel: string;
  image: {
    url: string;
  };
  pro: boolean;
  theme: ITheme;
  organisation: { id: number; name: string };
}

export type MarketplaceMembersSuccessPayload = IndexResponse<MarketplaceMember>;

export enum MarketplaceMembersViewType {
  committee = 'committee',
  all = 'all',
}
