import { PayloadAction } from '@reduxjs/toolkit';
import { CancelToken } from 'axios';
import { call, put, select } from 'redux-saga/effects';

import { getCancelRequestSource, getHTTPClient } from '@core/http-client';
import { configActions } from '@redux/reducers/config/configReducer';
import { savedViewsContactsActions } from '@redux/reducers/saved_views/contacts/contactsReducer';
import { savedViewsContactsSelectors } from '@redux/selectors/saved_views/contacts/savedViewContacts';
import {
  ManageSavedViewRequestPayload,
  PinUnpinSavedViewRequestPayload,
  SavedViewState,
  UpdateSavedViewRequestPayload,
} from '@redux/types/saved-views/common/savedViewsCommonTypes';
import { showNotification } from '@shared/components/notification';
import { getDefaultError } from '@shared/utils/common';


const $http = getHTTPClient();

const getSaveViews = () => {
  return $http.get(`/saved-views/crm-contacts`);
};

const getSavedViewById = (id: Id) => {
  return $http.get(`/saved-views/crm-contacts/${id}`);
};

const updatePinnedSavedViews = ({ userId, pinnedIds }: UpdateSavedViewRequestPayload, cancelToken: CancelToken) => {
  return $http.put(
    `/users/${userId}/update/pinned_views_crm_contacts`,
    {
      pinned_views_crm_contacts: pinnedIds,
    },
    { cancelToken }
  );
};

const deleteSavedView = (id: Id) => {
  return $http.delete(`/saved-views/${id}`);
};

const editSavedView = (data: ManageSavedViewRequestPayload) => {
  return $http.put(`/saved-views/${data.id}`, data);
};

const createSavedView = (data: ManageSavedViewRequestPayload) => {
  return $http.post(`/saved-views/crm-contacts`, data);
};

export function* getContactsSavedViewsSaga() {
  try {
    yield put(savedViewsContactsActions.setListLoading(true));

    const { data } = yield call(getSaveViews);

    yield put(savedViewsContactsActions.getListSuccess(data.data));
  } catch (e) {
    const errorMessage = getDefaultError(e?.repsponse?.data?.message);

    showNotification(errorMessage, 'error');
    yield put(savedViewsContactsActions.getListFailure(errorMessage));
  }
}

export function* getContactsSavedViewById(action: PayloadAction<Id>) {
  try {
    yield put(savedViewsContactsActions.setUnpinnedViewLoading(true));

    const { data } = yield call(() => getSavedViewById(action.payload));

    yield put(savedViewsContactsActions.getUnpinnedViewSuccess(data));
  } catch (e) {
    yield put(savedViewsContactsActions.getUnpinnedViewFailure(getDefaultError(e?.response?.data?.message)));
  }
}

export function* updatePinnedContactsSavedViewsSaga(action: PayloadAction<UpdateSavedViewRequestPayload>) {
  const source = getCancelRequestSource();

  try {
    yield put(savedViewsContactsActions.setUpdateViewsRequestLoading(true));

    const { data } = yield call(() => updatePinnedSavedViews(action.payload, source.token));

    yield put(savedViewsContactsActions.updateViewsSuccess());
    yield put(configActions.setUser(data));
  } catch (e) {
    yield put(savedViewsContactsActions.updateViewsFailure(getDefaultError(e?.response?.data?.message)));
  } finally {
    const { updateLoading }: SavedViewState = yield select(savedViewsContactsSelectors.getState);

    if (updateLoading) {
      source.cancel();
    }
  }
}

export function* pinSavedViewContactSaga(action: PayloadAction<PinUnpinSavedViewRequestPayload>) {
  const source = getCancelRequestSource();

  try {
    yield put(savedViewsContactsActions.setUpdateViewsRequestLoading(true));

    const { data } = yield call(() => updatePinnedSavedViews(action.payload, source.token));

    yield put(configActions.setUser(data));
    yield put(
      savedViewsContactsActions.pinViewSuccess({
        view: action.payload.view,
        isActiveView: action.payload.isActiveView,
      })
    );
  } finally {
    const { updateLoading }: SavedViewState = yield select(savedViewsContactsSelectors.getState);

    if (updateLoading) {
      source.cancel();
    }
  }
}

export function* unpinSavedViewContactSaga(action: PayloadAction<PinUnpinSavedViewRequestPayload>) {
  const source = getCancelRequestSource();

  try {
    yield put(savedViewsContactsActions.setUpdateViewsRequestLoading(true));

    const { data } = yield call(() => updatePinnedSavedViews(action.payload, source.token));

    yield put(configActions.setUser(data));
    yield put(
      savedViewsContactsActions.unpinViewSuccess({
        view: action.payload.view,
        isActiveView: action.payload.isActiveView,
      })
    );
  } finally {
    const { updateLoading }: SavedViewState = yield select(savedViewsContactsSelectors.getState);

    if (updateLoading) {
      source.cancel();
    }
  }
}

export function* deleteSavedViewContactsSaga(action: PayloadAction<Id>) {
  try {
    yield call(() => deleteSavedView(action.payload));

    yield put(configActions.deleteSavedViewContactsSuccess(action.payload));
    yield put(savedViewsContactsActions.deleteViewSuccess(action.payload));
  } catch (e) {
    showNotification(getDefaultError(e?.message), 'error');
  }
}

export function* editSavedViewContactsSaga(action: PayloadAction<ManageSavedViewRequestPayload>) {
  try {
    const { data } = yield call(() => editSavedView(action.payload));

    yield put(configActions.editSavedViewContactsSuccess(data));
    yield put(savedViewsContactsActions.editViewSuccess(data));
  } catch (e) {
    showNotification(
      getDefaultError('Sorry, we could not update your view. Please contact us for assistance.'),
      'error'
    );
  }
}

export function* createSavedViewContactsSaga(action: PayloadAction<ManageSavedViewRequestPayload>) {
  try {
    const { data } = yield call(() => createSavedView(action.payload));

    yield put(savedViewsContactsActions.createViewSuccess(data));
  } catch (e) {
    showNotification(getDefaultError(e?.message), 'error');
  }
}
