import { PayloadAction } from '@reduxjs/toolkit';
import { CancelToken } from 'axios';
import { call, put, select } from 'redux-saga/effects';

import { getCancelRequestSource, getHTTPClient } from '@core/http-client';
import { configActions } from '@redux/reducers/config/configReducer';
import { savedViewSocietyAcquisitionInsightsActions } from '@redux/reducers/saved_views/society-insights/savedViewSocietyAcquisitionInsightsReducer';
import { savedViewSocietyAcquisitionInsightsSelectors } from '@redux/selectors/saved_views/society-insights/savedViewSocietyAcquisitionInsightsSelector';
import {
  ManageSavedViewRequestPayload,
  PinUnpinSavedViewRequestPayload,
  SavedViewState,
  UpdateSavedViewRequestPayload,
} from '@redux/types/saved-views/common/savedViewsCommonTypes';
import { showNotification } from '@shared/components/notification';
import { getDefaultError } from '@shared/utils/common';


const $http = getHTTPClient();

const getSocietyAcquisitionInsights = () => {
  return $http.get(`/saved-views/society-acquisitions-insights`);
};

const getSocietyAcquisitionInsightsById = (id: Id) => {
  return $http.get(`/saved-views/society-acquisitions-insights/${id}`);
};

const updatePinnedSocietyAcquisitionInsightsSavedViews = (
  { userId, pinnedIds }: UpdateSavedViewRequestPayload,
  cancelToken: CancelToken
) => {
  return $http.put(
    `/users/${userId}/update/pinned_views_society_acquisition_insights`,
    {
      pinned_views_society_acquisition_insights: pinnedIds,
    },
    { cancelToken }
  );
};

const deleteSavedView = (id: Id) => {
  return $http.delete(`/saved-views/${id}`);
};

const editSavedView = (data: ManageSavedViewRequestPayload) => {
  return $http.put(`/saved-views/${data.id}`, data);
};

const createSavedView = (data: ManageSavedViewRequestPayload) => {
  return $http.post(`/saved-views/society-acquisitions-insights`, data);
};

export function* getSocietyAcquisitionInsightsSaga() {
  try {
    yield put(savedViewSocietyAcquisitionInsightsActions.setListLoading(true));

    const { data } = yield call(getSocietyAcquisitionInsights);
    yield put(savedViewSocietyAcquisitionInsightsActions.getListSuccess(data.data));
  } catch (e) {
    const errorMessage = getDefaultError(e?.message);

    showNotification(errorMessage, 'error');
    yield put(savedViewSocietyAcquisitionInsightsActions.getListFailure(errorMessage));
  }
}

export function* getSocietyAcquisitionInsightsByIdSaga(action: PayloadAction<Id>) {
  try {
    yield put(savedViewSocietyAcquisitionInsightsActions.setUnpinnedViewLoading(true));

    const { data } = yield call(() => getSocietyAcquisitionInsightsById(action.payload));
    yield put(savedViewSocietyAcquisitionInsightsActions.getUnpinnedViewSuccess(data));
  } catch (e) {
    yield put(savedViewSocietyAcquisitionInsightsActions.getUnpinnedViewFailure(getDefaultError(e?.message)));
  }
}

export function* updatePinnedSocietyAcquisitionInsightsSavedViewsSaga(
  action: PayloadAction<UpdateSavedViewRequestPayload>
) {
  const source = getCancelRequestSource();

  try {
    yield put(savedViewSocietyAcquisitionInsightsActions.setUpdateViewsRequestLoading(true));

    const { data } = yield call(() => updatePinnedSocietyAcquisitionInsightsSavedViews(action.payload, source.token));

    yield put(configActions.setUser(data));
    yield put(savedViewSocietyAcquisitionInsightsActions.updateViewsSuccess());
  } catch (e) {
    yield put(savedViewSocietyAcquisitionInsightsActions.updateViewsFailure(getDefaultError(e?.message)));
  } finally {
    const { updateLoading }: SavedViewState = yield select(savedViewSocietyAcquisitionInsightsSelectors.getState);

    if (updateLoading) {
      source.cancel();
    }
  }
}

export function* pinSocietyAcquisitionInsightsSavedViewsSaga(action: PayloadAction<PinUnpinSavedViewRequestPayload>) {
  const source = getCancelRequestSource();

  try {
    yield put(savedViewSocietyAcquisitionInsightsActions.setUpdateViewsRequestLoading(true));

    const { data } = yield call(() => updatePinnedSocietyAcquisitionInsightsSavedViews(action.payload, source.token));
    yield put(configActions.setUser(data));
    yield put(
      savedViewSocietyAcquisitionInsightsActions.pinViewSuccess({
        view: action.payload.view,
        isActiveView: action.payload.isActiveView,
      })
    );
  } finally {
    const { updateLoading }: SavedViewState = yield select(savedViewSocietyAcquisitionInsightsSelectors.getState);

    if (updateLoading) {
      source.cancel();
    }
  }
}

export function* unpinSocietyAcquisitionInsightsSavedViewsSaga(action: PayloadAction<PinUnpinSavedViewRequestPayload>) {
  const source = getCancelRequestSource();

  try {
    yield put(savedViewSocietyAcquisitionInsightsActions.setUpdateViewsRequestLoading(true));

    const { data } = yield call(() => updatePinnedSocietyAcquisitionInsightsSavedViews(action.payload, source.token));
    yield put(configActions.setUser(data));
    yield put(
      savedViewSocietyAcquisitionInsightsActions.unpinViewSuccess({
        view: action.payload.view,
        isActiveView: action.payload.isActiveView,
      })
    );
  } finally {
    const { updateLoading }: SavedViewState = yield select(savedViewSocietyAcquisitionInsightsSelectors.getState);

    if (updateLoading) {
      source.cancel();
    }
  }
}

export function* deleteSavedViewSocietyAcquisitionInsightsSaga(action: PayloadAction<Id>) {
  try {
    yield call(() => deleteSavedView(action.payload));

    yield put(configActions.deleteSavedViewSocietyAcquisitionInsightsSuccess(action.payload));
    yield put(savedViewSocietyAcquisitionInsightsActions.deleteViewSuccess(action.payload));
  } catch (e) {
    showNotification(getDefaultError(e?.message), 'error');
  }
}

export function* editSavedViewSocietyAcquisitionInsightsSaga(action: PayloadAction<ManageSavedViewRequestPayload>) {
  try {
    const { data } = yield call(() => editSavedView(action.payload));

    yield put(configActions.editSavedViewSocietyAcquisitionInsightsSuccess(data));
    yield put(savedViewSocietyAcquisitionInsightsActions.editViewSuccess(data));
  } catch (e) {
    showNotification(
      getDefaultError('Sorry, we could not update your view. Please contact us for assistance.'),
      'warning'
    );
  }
}

export function* createSavedViewSocietyAcquisitionInsightsSaga(action: PayloadAction<ManageSavedViewRequestPayload>) {
  try {
    const { data } = yield call(() => createSavedView(action.payload));

    yield put(savedViewSocietyAcquisitionInsightsActions.createViewSuccess(data));
  } catch (e) {
    showNotification(getDefaultError(e?.message), 'error');
  }
}
