import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@redux/reducers/rootReducer';

const layoutState = (state: RootState) => state.layout;

const getLayoutState = createSelector(layoutState, (state) => state);
const getNavMenuStatus = createSelector(layoutState, (state) => state.navMenuCollapsed);

export const layoutSelectors = {
  getLayoutState,
  getNavMenuStatus,
};
