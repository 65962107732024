import { PayloadAction } from '@reduxjs/toolkit';
import { CancelToken } from 'axios';
import { call, put, select } from 'redux-saga/effects';

import { getCancelRequestSource, getHTTPClient } from '@core/http-client';
import { configActions } from '@redux/reducers/config/configReducer';
import { savedViewsDisposalsActions } from '@redux/reducers/saved_views/disposals/disposalsReducer';
import { savedViewsDisposalsSelectors } from '@redux/selectors/saved_views/disposals/savedViewDisposals';
import {
  ManageSavedViewRequestPayload,
  PinUnpinSavedViewRequestPayload,
  SavedViewState,
  UpdateSavedViewRequestPayload,
} from '@redux/types/saved-views/common/savedViewsCommonTypes';
import { showNotification } from '@shared/components/notification';
import { getDefaultError } from '@shared/utils/common';


const $http = getHTTPClient();

const getSaveViews = () => {
  return $http.get(`/saved-views/disposals`);
};

const getSavedViewById = (id: Id) => {
  return $http.get(`/saved-views/disposals/${id}`);
};

const updatePinnedSavedViews = ({ userId, pinnedIds }: UpdateSavedViewRequestPayload, cancelToken: CancelToken) => {
  return $http.put(
    `/users/${userId}/update/pinned_views_disposals`,
    {
      pinned_views_disposals: pinnedIds,
    },
    { cancelToken }
  );
};

const deleteSavedView = (id: Id) => {
  return $http.delete(`/saved-views/${id}`);
};

const editSavedView = (data: ManageSavedViewRequestPayload) => {
  return $http.put(`/saved-views/${data.id}`, data);
};

const createSavedView = (data: ManageSavedViewRequestPayload) => {
  return $http.post(`/saved-views/disposals`, data);
};

export function* getDisposalsSavedViewsSaga() {
  try {
    yield put(savedViewsDisposalsActions.setListLoading(true));

    const { data } = yield call(getSaveViews);

    yield put(savedViewsDisposalsActions.getListSuccess(data.data));
  } catch (e) {
    const errorMessage = getDefaultError(e?.repsponse?.data?.message);

    showNotification(errorMessage, 'error');
    yield put(savedViewsDisposalsActions.getListFailure(errorMessage));
  }
}

export function* getDisposalsSavedViewById(action: PayloadAction<Id>) {
  try {
    yield put(savedViewsDisposalsActions.setUnpinnedViewLoading(true));

    const { data } = yield call(() => getSavedViewById(action.payload));

    yield put(savedViewsDisposalsActions.getUnpinnedViewSuccess(data));
  } catch (e) {
    yield put(savedViewsDisposalsActions.getUnpinnedViewFailure(getDefaultError(e?.response?.data?.message)));
  }
}

export function* updatePinnedDisposalsSavedViewsSaga(action: PayloadAction<UpdateSavedViewRequestPayload>) {
  const source = getCancelRequestSource();

  try {
    yield put(savedViewsDisposalsActions.setUpdateViewsRequestLoading(true));

    const { data } = yield call(() => updatePinnedSavedViews(action.payload, source.token));

    yield put(savedViewsDisposalsActions.updateViewsSuccess());
    yield put(configActions.setUser(data));
  } catch (e) {
    yield put(savedViewsDisposalsActions.updateViewsFailure(getDefaultError(e?.response?.data?.message)));
  } finally {
    const { updateLoading }: SavedViewState = yield select(savedViewsDisposalsSelectors.getState);

    if (updateLoading) {
      source.cancel();
    }
  }
}

export function* pinSavedViewDisposalSaga(action: PayloadAction<PinUnpinSavedViewRequestPayload>) {
  const source = getCancelRequestSource();

  try {
    yield put(savedViewsDisposalsActions.setUpdateViewsRequestLoading(true));

    const { data } = yield call(() => updatePinnedSavedViews(action.payload, source.token));

    yield put(configActions.setUser(data));
    yield put(
      savedViewsDisposalsActions.pinViewSuccess({
        view: action.payload.view,
        isActiveView: action.payload.isActiveView,
      })
    );
  } finally {
    const { updateLoading }: SavedViewState = yield select(savedViewsDisposalsSelectors.getState);

    if (updateLoading) {
      source.cancel();
    }
  }
}

export function* unpinSavedViewDisposalSaga(action: PayloadAction<PinUnpinSavedViewRequestPayload>) {
  const source = getCancelRequestSource();

  try {
    yield put(savedViewsDisposalsActions.setUpdateViewsRequestLoading(true));

    const { data } = yield call(() => updatePinnedSavedViews(action.payload, source.token));

    yield put(configActions.setUser(data));
    yield put(
      savedViewsDisposalsActions.unpinViewSuccess({
        view: action.payload.view,
        isActiveView: action.payload.isActiveView,
      })
    );
  } finally {
    const { updateLoading }: SavedViewState = yield select(savedViewsDisposalsSelectors.getState);

    if (updateLoading) {
      source.cancel();
    }
  }
}

export function* deleteSavedViewDisposalsSaga(action: PayloadAction<Id>) {
  try {
    yield call(() => deleteSavedView(action.payload));

    yield put(configActions.deleteSavedViewDisposalsSuccess(action.payload));
    yield put(savedViewsDisposalsActions.deleteViewSuccess(action.payload));
  } catch (e) {
    showNotification(getDefaultError(e?.message), 'error');
  }
}

export function* editSavedViewDisposalsSaga(action: PayloadAction<ManageSavedViewRequestPayload>) {
  try {
    const { data } = yield call(() => editSavedView(action.payload));

    yield put(configActions.editSavedViewDisposalsSuccess(data));
    yield put(savedViewsDisposalsActions.editViewSuccess(data));
  } catch (e) {
    showNotification(
      getDefaultError('Sorry, we could not update your view. Please contact us for assistance.'),
      'error'
    );
  }
}

export function* createSavedViewDisposalsSaga(action: PayloadAction<ManageSavedViewRequestPayload>) {
  try {
    const { data } = yield call(() => createSavedView(action.payload));

    yield put(savedViewsDisposalsActions.createViewSuccess(data));
  } catch (e) {
    showNotification(getDefaultError(e?.message), 'error');
  }
}
