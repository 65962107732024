import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';

import { getHTTPClient } from '@core/http-client';
import { manageTasksActions } from '@redux/reducers/diary/tasks/tasksReducer';
import { InsightsViewingTaskDTO } from '@shared/models/insightsViewing/insightsViewingTask';
import { PromisePayload } from '@shared/types/redux';
import { getDefaultError } from '@shared/utils/common';

const $http = getHTTPClient();

export const createNewTask = (data: InsightsViewingTaskDTO) => {
  return $http.post(`/tasks`, data);
};

export function* createNewTaskSaga(action: PayloadAction<PromisePayload<{data: InsightsViewingTaskDTO}>>) {
  const { payload, resolve, reject } = action.payload;

  try {
    yield put(manageTasksActions.setManageTasksLoading(true));

    const { data } = yield call(() => createNewTask(payload.data));
    yield put(manageTasksActions.getManageTasksSuccess(data));
    yield call(() => resolve());
  } catch (e) {
    yield put(manageTasksActions.getManageTasksFailure(getDefaultError(e?.message)));
    yield call(() => reject(e));
  }
}
