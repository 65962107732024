import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@redux/reducers/rootReducer';

const marketplaceMembersState = (state: RootState) => state.marketplaceMembers;

const getState = createSelector(marketplaceMembersState, (state) => state);

export const marketplaceMembersSelectors = {
  getState,
};
