import { PayloadAction } from '@reduxjs/toolkit';
import { CancelToken } from 'axios';
import { call, put, select } from 'redux-saga/effects';

import { getCancelRequestSource, getHTTPClient } from '@core/http-client';
import { configActions } from '@redux/reducers/config/configReducer';
import { savedViewsSalesActions } from '@redux/reducers/saved_views/sales/salesReducer';
import { savedViewsSalesSelectors } from '@redux/selectors/saved_views/sales/savedViewSales';
import {
  ManageSavedViewRequestPayload,
  PinUnpinSavedViewRequestPayload,
  SavedViewState,
  UpdateSavedViewRequestPayload,
} from '@redux/types/saved-views/common/savedViewsCommonTypes';
import { showNotification } from '@shared/components/notification';
import { getDefaultError } from '@shared/utils/common';


const $http = getHTTPClient();

const getSales = () => {
  return $http.get(`/saved-views/sale-transactions`);
};

const getSalesById = (id: Id) => {
  return $http.get(`/saved-views/sale-transactions/${id}`);
};

const updatePinnedSalesSavedViews = (
  { userId, pinnedIds }: UpdateSavedViewRequestPayload,
  cancelToken: CancelToken
) => {
  return $http.put(
    `/users/${userId}/update/pinned_views_sale_transactions`,
    {
      pinned_views_sale_transactions: pinnedIds,
    },
    { cancelToken }
  );
};

const deleteSavedView = (id: Id) => {
  return $http.delete(`/saved-views/${id}`);
};

const editSavedView = (data: ManageSavedViewRequestPayload) => {
  return $http.put(`/saved-views/${data.id}`, data);
};

const createSavedView = (data: ManageSavedViewRequestPayload) => {
  return $http.post(`/saved-views/sale-transactions`, data);
};

export function* getSalesSaga() {
  try {
    yield put(savedViewsSalesActions.setListLoading(true));

    const { data } = yield call(getSales);
    yield put(savedViewsSalesActions.getListSuccess(data.data));
  } catch (e) {
    const errorMessage = getDefaultError(e?.message);

    showNotification(errorMessage, 'error');
    yield put(savedViewsSalesActions.getListFailure(errorMessage));
  }
}

export function* getSalesByIdSaga(action: PayloadAction<Id>) {
  try {
    yield put(savedViewsSalesActions.setUnpinnedViewLoading(true));

    const { data } = yield call(() => getSalesById(action.payload));
    yield put(savedViewsSalesActions.getUnpinnedViewSuccess(data));
  } catch (e) {
    yield put(savedViewsSalesActions.getUnpinnedViewFailure(getDefaultError(e?.message)));
  }
}

export function* updatePinnedSalesSavedViewsSaga(action: PayloadAction<UpdateSavedViewRequestPayload>) {
  const source = getCancelRequestSource();

  try {
    yield put(savedViewsSalesActions.setUpdateViewsRequestLoading(true));

    const { data } = yield call(() => updatePinnedSalesSavedViews(action.payload, source.token));

    yield put(configActions.setUser(data));
    yield put(savedViewsSalesActions.updateViewsSuccess());
  } catch (e) {
    yield put(savedViewsSalesActions.updateViewsFailure(getDefaultError(e?.message)));
  } finally {
    const { updateLoading }: SavedViewState = yield select(savedViewsSalesSelectors.getState);

    if (updateLoading) {
      source.cancel();
    }
  }
}

export function* pinSalesSavedViewsSaga(action: PayloadAction<PinUnpinSavedViewRequestPayload>) {
  const source = getCancelRequestSource();

  try {
    yield put(savedViewsSalesActions.setUpdateViewsRequestLoading(true));

    const { data } = yield call(() => updatePinnedSalesSavedViews(action.payload, source.token));
    yield put(configActions.setUser(data));
    yield put(
      savedViewsSalesActions.pinViewSuccess({
        view: action.payload.view,
        isActiveView: action.payload.isActiveView,
      })
    );
  } finally {
    const { updateLoading }: SavedViewState = yield select(savedViewsSalesSelectors.getState);

    if (updateLoading) {
      source.cancel();
    }
  }
}

export function* unpinSalesSavedViewsSaga(action: PayloadAction<PinUnpinSavedViewRequestPayload>) {
  const source = getCancelRequestSource();

  try {
    yield put(savedViewsSalesActions.setUpdateViewsRequestLoading(true));

    const { data } = yield call(() => updatePinnedSalesSavedViews(action.payload, source.token));
    yield put(configActions.setUser(data));
    yield put(
      savedViewsSalesActions.unpinViewSuccess({
        view: action.payload.view,
        isActiveView: action.payload.isActiveView,
      })
    );
  } finally {
    const { updateLoading }: SavedViewState = yield select(savedViewsSalesSelectors.getState);

    if (updateLoading) {
      source.cancel();
    }
  }
}

export function* deleteSavedViewSalesSaga(action: PayloadAction<Id>) {
  try {
    yield call(() => deleteSavedView(action.payload));

    yield put(configActions.deleteSavedViewSalesSuccess(action.payload));
    yield put(savedViewsSalesActions.deleteViewSuccess(action.payload));
  } catch (e) {
    showNotification(getDefaultError(e?.message), 'error');
  }
}

export function* editSavedViewSalesSaga(action: PayloadAction<ManageSavedViewRequestPayload>) {
  try {
    const { data } = yield call(() => editSavedView(action.payload));

    yield put(configActions.editSavedViewSalesSuccess(data));
    yield put(savedViewsSalesActions.editViewSuccess(data));
  } catch (e) {
    showNotification(
      getDefaultError('Sorry, we could not update your view. Please contact us for assistance.'),
      'warning'
    );
  }
}

export function* createSavedViewSalesSaga(action: PayloadAction<ManageSavedViewRequestPayload>) {
  try {
    const { data } = yield call(() => createSavedView(action.payload));

    yield put(savedViewsSalesActions.createViewSuccess(data));
  } catch (e) {
    showNotification(getDefaultError(e?.message), 'error');
  }
}
